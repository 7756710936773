import React from "react";
import { Link, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import SEO from "../components/seo";
import Layout from "../components/layout/layout";
import Img from "gatsby-image";

import "./blog.scss";

export default function Index({ data, pageContext }) {
    const { edges: posts } = data.allMarkdownRemark;
    const { currentPage, numPages } = pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString();
    const nextPage = (currentPage + 1).toString();
    return (
        <React.Fragment>
            <SEO title="Blog" />
            <Layout pageId="blog">
                <div className="blog-archive-container">
                    <h1 className="page-title">Blog</h1>
                    <div className="blog-posts">
                        {posts
                            .filter(
                                (post) => post.node.frontmatter.title.length > 0
                            )
                            .map(({ node: post }) => {
                                // let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid;

                                return (
                                    <div
                                        className="blog-post-preview"
                                        key={post.id}
                                    >
                                        <div className="header">
                                            {post.frontmatter.featuredImage && (
                                                <Img
                                                    className="header-left"
                                                    fluid={
                                                        post.frontmatter
                                                            .featuredImage
                                                            .childImageSharp
                                                            .fluid
                                                    }
                                                />
                                            )}
                                            <div className="header-right">
                                                <Link
                                                    to={`${post.frontmatter.path}/`}
                                                >
                                                    <span className="title">
                                                        {post.frontmatter.title}
                                                    </span>
                                                </Link>
                                                <h2 className="date">
																										{post.frontmatter.date}
                                                </h2>
                                            </div>
                                        </div>

                                        <p className="content">
                                            {post.excerpt}
                                        </p>
                                        <Link
                                            className="seguir"
                                            to={`${post.frontmatter.path}/`}
                                        >
                                            <i>Seguir leyendo</i>
                                        </Link>
                                    </div>
                                );
                            })}
                    </div>
                    <div className="pagination-controls">
                        {!isLast && (
                            <Link to={`/blog/${nextPage}/`} rel="next">
                                ← Anteriores
                            </Link>
                        )}
                        {isLast && <span>← Anteriores</span>}

                        {!isFirst && (
                            <Link to={`/blog/${prevPage}/`} rel="prev">
                                 Siguientes →
                            </Link>
                        )}
                        {isFirst && <span>Siguientes →</span>}

                        {/* {Array.from({ length: numPages }, (_, i) => (
                            <Link
                                key={`pagination-number${i + 1}`}
                                to={`/${i === 0 ? "" : i + 1}`}
                            >
                                Página {i + 1}
                            </Link>
                        ))} */}
                    </div>
                </div>
            </Layout>
        </React.Fragment>
    );
}

export const pageQuery = graphql`
  query IndexQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY", locale: "es")
            path
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
